@import "breakpoints";

/* Typography */
@font-face {
  font-family: "Reem Kufi";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Reem Kufi Regular"), local("ReemKufi-Regular"),
    url("./reem-kufi-v4-latin-regular.woff2") format("woff2"),
    url("./reem-kufi-v4-latin-regular.woff") format("woff");
}

html {
  font-size: 12px;

  @include breakpoint-small-up {
    font-size: 14px;
  }

  @include breakpoint-medium-up {
    font-size: 16px;
  }

  @include breakpoint-large-up {
    font-size: 18px;
  }

  @include breakpoint-xxlarge-up {
    font-size: 20px;
  }
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  font-family: "Verdana", sans-serif;
  font-weight: 300;
}

h1 {
  font-weight: 900;
}

.content-grid {
  margin: 0 auto;
  width: calc(100% - 5rem); // mobile

  @include breakpoint-medium-up {
    width: calc(100% - 5rem);
  }

  @include breakpoint-large-up {
    width: calc(100% - 10rem);
  }

  @include breakpoint-xlarge-up {
    width: calc(100% - 20rem);
  }
}

::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
}
